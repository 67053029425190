import './RateMyRaise.css';
import { useState } from 'react';

export default function RateMyRaise() {
    const [ daysInOffice, setDaysInOffice ] = useState(0);
    const [ wageType, setWageType ] = useState("hourly");
    const [ oldWage, setOldWage] = useState(0);
    const [ newWage, setNewWage ] = useState(0);
    const [ distance, setDistance ] = useState(0);
    const [ beverages, setBeverageCost ] = useState(0);
    const [ meals, setMealCost ] = useState(0);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        let monthlyWage = 0;
        const wageDifference = newWage - oldWage;
        let monthlyGrossProfit = 0;
        if (wageType === "hourly") {
            monthlyWage = oldWage;
            monthlyGrossProfit = wageDifference * 8 * 20; 
        } else {
            monthlyWage = oldWage / 12;
            monthlyGrossProfit = wageDifference / 12;
        }

        monthlyGrossProfit *= .69;
        const fuelCostsPerDay = distance * .67;
        monthlyGrossProfit -= (fuelCostsPerDay * daysInOffice * 4.5);
        monthlyGrossProfit -= (beverages * daysInOffice * 4.5);
        monthlyGrossProfit -= (meals * daysInOffice * 4.5);
        alert(`After accounting for taxes & social security, commute costs, fuel costs, and meal costs, your new raise will net you ${monthlyGrossProfit} per month.`);
    }
    
    return (
        <div id="rmrScreen">
            <form id="rmrForm" onSubmit={handleSubmit}>
                <div id="daysInOffice">
                    <h1 id="header">Rate My Raise</h1>
                    <p className="rmrText">How many days per week will you be in-office?</p>
                    <div id="daysRadio">
                        <input type="radio" className="rmrDaysRadioButtons" name="schedule" id="fiveDays" value="5" onChange={(e) => setDaysInOffice(e.target.value)}/>
                        <label for="fiveDays" className="rmrDaysRadioButtonsLabels">5</label>
                        <input type="radio" name="schedule" id="fourDays" value="4" onChange={(e) => setDaysInOffice(Number(e.target.value))}/>
                        <label for="fourDays" className="rmrDaysRadioButtonsLabels">4</label>
                        <input type="radio" name="schedule" id="threeDays" value="3" onChange={(e) => setDaysInOffice(Number(e.target.value))}/>
                        <label for="threeDays" className="rmrDaysRadioButtonsLabels">3</label>
                        <input type="radio" name="schedule" id="twoDays" value="2" onChange={(e) => setDaysInOffice(Number(e.target.value))}/>
                        <label for="twoDays" className="rmrDaysRadioButtonsLabels">2</label>
                        <input type="radio" name="schedule" id="oneDays" value="1" onChange={(e) => setDaysInOffice(Number(e.target.value))}/>
                        <label for="oneDays" className="rmrDaysRadioButtonsLabels">1</label>
                        <input type="radio" name="schedule" id="zeroDays" value="0" onChange={(e) => setDaysInOffice(Number(e.target.value))}/>
                        <label for="zeroDays" className="rmrDaysRadioButtonsLabels">0 (fully remote)</label>
                    </div>
                    <div>
                        <p className="rmrText">Are you paid by the hour, or per a salary?</p>
                        <div id="hourlySalaryRadio">
                            <input type="radio" className="rmrDaysRadioButtons" name="wage" id="hourly" value="hourly" onChange={(e) => setWageType(e.target.value)}/>
                            <label for="hourly" className="rmrDaysRadioButtonsLabels">Hourly</label>
                            <input type="radio" className="rmrDaysRadioButtons" name="wage" id="salary" value="salary" onChange={(e) => setWageType(e.target.value)}/>
                            <label for="salary" className="rmrDaysRadioButtonsLabels">Salary</label>
                        </div>
                    </div>
                    
                    <div id="grey">
                        <p className="rmrText">At your current rate (before your raise), how much money do you make {(wageType === "hourly") ? "per hour?" : "annually?"}</p>
                        <input type="text" className="rmrTextInput" name="oldWage" onChange={(e) => setOldWage(Number(e.target.value))}/>
                        <p className="rmrText">After your new raise, how much money will you make {(wageType === "hourly") ? "per hour?" : "annually?"}</p>
                        <input type="text" className="rmrTextInput" name="newWage" onChange={(e) => setNewWage(Number(e.target.value))}/>
                    </div>
                    <p className="rmrText">How far away (miles) is your home from your NEW job?</p>
                    <input type="text" className="rmrTextInput" name="distance" onChange={(e) => setDistance(Number(e.target.value))}/>
                    <p className="rmrText">How much money will you spend on beverages (coffee/tea/energy drinks/etc) every workday?</p>
                    <input type="text" className="rmrTextInput" name="beverages" onChange={(e) => setBeverageCost(Number(e.target.value))}/>
                    <p className="rmrText">How much money will you spend on meals every workday?</p>
                    <input type="text" className="rmrTextInput" name="meals" onChange={(e) => setMealCost(Number(e.target.value))}/>
                </div>
            <button id="submitBtnRMR" onSubmit={handleSubmit}>Submit</button>
            </form>
        </div>
    )
}