import './DiceOnline.css';
import { useState } from 'react';

export default function DiceOnline() {
    const [ roll, setRoll ] = useState('\u29D6');
    const [ sides, setSides ] = useState(null);

    const validateSides = () => {
        if (isNaN(sides) === true) {
            alert("ERROR: Not a number! Please enter a valid number from 2-99");
            return false;
        } else if (sides < 2) {
            alert("ERROR: Less than 2! Cannot have a die with only one, zero, or negative sides!. Please enter a valid number from 2-99");
            return false;
        } else if (sides > 99) {
            alert("ERROR: More than 99! Please enter a valid number from 2-99");
            return false;
        } else if (sides % 1 !== 0) {
            alert("ERROR: Decimals are not allowed! Please enter a valid number from 2-99");
        } else {
            return true;
        }
    }
    
    const rollDice = () => {
        if (validateSides() === true) {
            let randomNum = Math.floor(Math.random() * sides) + 1;
            if (randomNum > sides) {
                randomNum -= 1;
            } else if (randomNum === 0) {
                randomNum += 1;
            }
            setRoll(randomNum);
        }
    }

    return (
    <div id="do_screen">
        <h1 className="diceHeader">Dice Online</h1>
        <p className="diceParagraph">This is a very simple app!</p>
        <p className="diceParagraph">Enter the number of sides for your dice</p>
        <p className="diceParagraph">Then click "Roll" to see a simulated dice roll!</p>
        <div id="do_game">
            <input type="text" id="dsides" onChange={(e) => setSides(Number(e.target.value))}></input>
            <button className="rollButton" onClick={rollDice}>Roll</button>
            <h1 id="diceRoll">{roll}</h1>
        </div>
    </div>
    );
}