import './SoftwarePortfolio.css';
import './SharedStyles.css';

function SoftwarePortfolio() {
    return (
        <div className="fadedInOutlet" id="screen">
            <div className="screenHeading">
                <h1 className="bigText">Hi! I'm Matt!</h1>
                <h3 className="mediumText">I am a freelance web developer in Oregon, seeking work!</h3>
                <div className="smallTextParagraph">
                    <p className="smallText">Since September 2023, I have worked as a freelancer for UniverCity Inc. I am developing a web app called <a href="http://uvcsandbox.com">Access 2 Care</a> that will help Florida Keys residents connect with local healthcare providers within their insurance networks.</p>
                    <p className="smallText">The user-facing front-end of the application is being developed in ReactJS with HTML & CSS. There is a separate portal for healthcare providers which is being developed in Django. </p>
                    <p className="smallText">Our back-end is a Linux server running Apache, with an SQL data layer, and a PHP middle layer to connect everything.</p>
                </div>
                <div className="smallTextParagraph">
                    <p className="smallText">I have also worked on several smaller portfolio pieces!</p>
                    <ul>
                        <li className="smallText"><a href="http://bagleycode.com/check-with-me">Check with Me</a>, a fantasy football helper that checks if my players are OUT or AVAILABLE before kickoff. It includes a ReactJS front-end, and a RESTful API written in NodeJS & Express.</li><br/>
                        <li className="smallText"><a href="http://bagleycode.com/dice-online">Dice Online</a>, a dice roller for my Axis Allies & RISK games. This lean, simple app is front-end only, written in ReactJS.</li><br/>
                        <li className="smallText"><a href="http://bagleycode.com/rate-my-raise">Rate My Raise</a>, a quick estimator that calculates the difference in net profit between a user's current & future wage, factoring in hourly or salaried rates, tax rates, commute costs, and more. This was written in ReactJS.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default SoftwarePortfolio;